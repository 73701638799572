import React, {useEffect, useRef, useState} from "react";
import {
    Button, Dialog,
    DialogActions, DialogContent, DialogContentText,
    FormControlLabel,
    FormLabel,
    Typography,
    Radio,
    RadioGroup,
    TextField,
    Box
} from "@mui/material";
import {Grid, Divider} from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {FilePond, registerPlugin} from "react-filepond";
import "filepond/dist/filepond.min.css";
import api from "../api";

const Profile = () => {
    const [profiles, setProfiles] = useState([]);
    const [selectedProfile, setSelectedProfile] = useState(null);
    const [profileSettings, setProfileSettings] = useState({});
    const [dialogOpen, setDialogOpen] = useState(false);
    const [profileName, setProfileName] = useState('');

    const [selectedFile, setSelectedFile] = useState([]);
    const [selectedFile2, setSelectedFile2] = useState([]);

    const getProfiles = () => {
        api.get('/api/v1/profile_list/')
            .then((response) => {
                api.get('/api/v1/profile_settings/')
                    .then((profile_settings) => {
                        setProfileSettings(profile_settings.data)
                        setProfiles(response.data)
                        setSelectedProfile(response.data[0])
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const createProfile = () => {
        if (profileName !== '') {
            const new_profile = {
                name: profileName,
                // watermark_file: profileSettings.watermarks[0],
                sound_file: profileSettings.audios[0],
                editing_encoding: profileSettings.editing_encodings[0]['name'],
                result_encoding: profileSettings.result_encodings[0]['name'],
                intro: 'none',
                endslate: 'none',
                profile_type: 'preset',
                preset: 10,
                blocks: [
                    {
                        start: 0,
                        end: 5,
                        watermark_file: profileSettings.watermarks[0],
                        timescale: 'none',
                        transition: 'none',
                        filter: 'none',
                    },
                    {
                        start: 5,
                        end: 10,
                        watermark_file: profileSettings.watermarks[0],
                        timescale: 'none',
                        transition: 'none',
                        filter: 'none',
                    },
                ],
                dynamic_effects: {
                    'watermark_file': profileSettings.watermarks[0],
                    'timescale': 'none',
                    'filter': 'none',
                    'lut_filter': 'none',
                }
            }
            setProfiles([...profiles, new_profile])
            setSelectedProfile(new_profile)
            setProfileName('')
        }

        handleDialogClose()
    }

    const saveCurrentProfile = () => {
        const profile = selectedProfile
        if (Object.hasOwn(profile, 'id')) {
            api.put(`/api/v1/profile/${profile.id}/`, profile).then(
                (response) => {
                    console.log(response)
                }
            ).catch(
                (error) => {
                    console.log(error)
                }
            )
        } else {
            api.post('/api/v1/profile/', profile).then(
                (response) => {
                    console.log(response)
                }
            ).catch(
                (error) => {
                    console.log(error)
                }
            )
        }
    }

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    useEffect(() => {
        getProfiles();
    }, []);

    const handleDynamicEffectChange = (field, value) => {
        setSelectedProfile({...selectedProfile, dynamic_effects: {...selectedProfile.dynamic_effects, [field]: value}});
    }

    const handleBlockChange = (blockIndex, field, value) => {
        const updatedBlocks = selectedProfile?.blocks.map((block, index) => {
            if (index === blockIndex) {
                return {...block, [field]: value};
            }
            return block;
        });
        if (updatedBlocks) {
            setSelectedProfile({...selectedProfile, blocks: updatedBlocks});
        }
    };

    const handleBlockDelete = (blockIndex) => {
        const updatedBlocks = selectedProfile?.blocks.filter((block, index) => {
            return index !== blockIndex;
        });
        if (updatedBlocks) {
            setSelectedProfile({...selectedProfile, blocks: updatedBlocks});
        }
    }

    const handleBlockAdd = () => {
        const newBlock = {
            start: 0,
            end: 5,
            watermark_file: profileSettings.watermarks[0],
            timescale: 'none',
            transition: 'none',
            filter: 'none',
        }
        const updatedBlocks = selectedProfile?.blocks.concat(newBlock);
        if (updatedBlocks) {
            setSelectedProfile({...selectedProfile, blocks: updatedBlocks});
        }
    }

    const presetProfileOptions = () => {
        return (
            <div>
                <Grid item xs={12} sx={{ml: '50px', mt: '30px'}}>
                    <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">Preset Time Seconds</FormLabel>
                        <TextField type="number"
                                   label=""
                                   inputProps={{min: 5, max: 999}}
                                   value={selectedProfile.preset}
                                   onChange={

                                       (e) => {
                                           let inputValue = e.target.value;


                                           if (inputValue === '') {
                                               inputValue = 5
                                           }

                                           // Only allow positive integers
                                           if (/^\d+$/.test(inputValue)) {
                                               setSelectedProfile({...selectedProfile, preset: inputValue})
                                           }
                                       }
                                   }/>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sx={{mt: '30px', ml: '40px'}}>
                    <Typography variant="h5" gutterBottom>
                        Video Blocks
                    </Typography>
                    {selectedProfile.blocks.map((block, index) => (
                        <Grid container spacing={2} key={index} alignItems="center" sx={{
                            borderRadius: 1,
                            backgroundColor: index % 2 === 0 ? 'grey.100' : 'grey.200',
                            padding: '16px',
                            mt: '16px',
                            border: '1px solid grey.300'
                        }}>
                            <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                                <FormLabel id="demo-row-radio-buttons-group-label">Watermark</FormLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Watermark"
                                    onChange={(e) => {
                                        handleBlockChange(index, 'watermark_file', e.target.value)
                                    }}
                                    value={block.watermark_file}
                                >
                                    {profileSettings.watermarks.map((watermark) => (
                                        <MenuItem key={watermark} value={watermark}>
                                            {watermark}
                                        </MenuItem>)
                                    )}
                                </Select>
                            </FormControl>

                            <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                                <FormLabel id="demo-row-radio-buttons-group-label">Transition</FormLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Watermark"
                                    onChange={(e) => {
                                        handleBlockChange(index, 'transition', e.target.value)
                                    }}
                                    value={block.transition}
                                >
                                    {profileSettings.transitions.map((transition) => (
                                        <MenuItem key={transition} value={transition}>
                                            {transition}
                                        </MenuItem>)
                                    )}
                                </Select>
                            </FormControl>

                            <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                                <FormLabel id="demo-row-radio-buttons-group-label">Filter</FormLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Filter"
                                    onChange={(e) => {
                                        handleBlockChange(index, 'filter', e.target.value)
                                    }}
                                    value={block.filter}
                                >
                                    {profileSettings.filters.map((filter) => (
                                        <MenuItem key={filter} value={filter}>
                                            {filter}
                                        </MenuItem>)
                                    )}
                                </Select>
                            </FormControl>


                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">Start</FormLabel>
                                <TextField
                                    id="number-input"
                                    type="number"
                                    variant="standard"
                                    value={block.start}
                                    onChange={(e) => {
                                        handleBlockChange(index, 'start', e.target.value);
                                    }}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">End</FormLabel>
                                <TextField
                                    id="number-input"
                                    type="number"
                                    variant="standard"
                                    value={block.end}
                                    onChange={(e) => {
                                        handleBlockChange(index, 'end', e.target.value);
                                    }}
                                />
                            </FormControl>
                            <Button
                                variant="contained"
                                color="secondary"
                                sx={{mt: 1}}
                                onClick={() => handleBlockDelete(index)}
                            >
                                Delete Block
                            </Button>

                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">Timescale</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    onChange={(e) => {
                                        handleBlockChange(index, 'timescale', e.target.value)
                                    }}
                                    value={block.timescale}
                                >
                                    <FormControlLabel value="none" control={<Radio/>} label="None"/>
                                    <FormControlLabel value="slow_motion" control={<Radio/>}
                                                      label="Slow Motion"/>
                                    <FormControlLabel value="ramp" control={<Radio/>} label="Ramp"/>
                                </RadioGroup>
                            </FormControl>

                        </Grid>))}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleBlockAdd}
                    >
                        Add Block
                    </Button>
                </Grid>
            </div>
        )
    }

    const dynamicProfileOptions = () => {
        return (
            <div>
                <Grid item xs={12} sx={{mt: '30px', ml: '40px'}}>
                    <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                        <FormLabel id="demo-row-radio-buttons-group-label">Watermark</FormLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Watermark"
                            onChange={(e) => {
                                handleDynamicEffectChange('watermark_file', e.target.value)
                            }}
                            value={selectedProfile.dynamic_effects.watermark_file}
                        >
                            {profileSettings.watermarks.map((watermark) => (
                                <MenuItem key={watermark} value={watermark}>
                                    {watermark}
                                </MenuItem>)
                            )}
                        </Select>
                    </FormControl>

                    <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                        <FormLabel id="demo-row-radio-buttons-group-label">Filter</FormLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Filter"
                            onChange={(e) => {
                                handleDynamicEffectChange('filter', e.target.value)
                            }}
                            value={selectedProfile.dynamic_effects.filter}
                        >
                            {profileSettings.filters.map((filter) => (
                                <MenuItem key={filter} value={filter}>
                                    {filter}
                                </MenuItem>)
                            )}
                        </Select>
                    </FormControl>

                    <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                        <FormLabel id="demo-row-radio-buttons-group-label">Lut Filter</FormLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Filter"
                            onChange={(e) => {
                                handleDynamicEffectChange('lut_filter', e.target.value)
                            }}
                            value={selectedProfile.dynamic_effects.lut_filter}
                        >
                            {profileSettings.lut_filters.map((filter) => (
                                <MenuItem key={filter} value={filter}>
                                    {filter}
                                </MenuItem>)
                            )}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sx={{mt: '30px', ml: '40px'}}>
                    <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">Timescale</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={(e) => {
                                handleDynamicEffectChange('timescale', e.target.value)
                            }}
                            value={selectedProfile.dynamic_effects.timescale}
                        >
                            <FormControlLabel value="none" control={<Radio/>} label="None"/>
                            <FormControlLabel value="slow_motion" control={<Radio/>}
                                              label="Slow Motion"/>
                            <FormControlLabel value="ramp" control={<Radio/>} label="Ramp"/>
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </div>
        )
    }

    return (

        <Grid
            container
            alignItems="center"
            justifyContent="start"
            // style={{minHeight: '100vh'}}
        >

            <Grid item xs={12} sx={{mt: '30px', ml: '40px'}}>
                <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Profile"
                        value={selectedProfile ? selectedProfile.name : ''}
                        onChange={(e) => {
                            const profile = profiles.find((profile) => profile.name === e.target.value)
                            setSelectedProfile(profile)
                        }}
                    >
                        {profiles.map((profile) => (
                            <MenuItem key={profile.name} value={profile.name}>
                                {profile.name}
                            </MenuItem>)
                        )}
                    </Select>
                </FormControl>

                <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                    <Button variant="contained" onClick={() => saveCurrentProfile()}>Save</Button>
                </FormControl>
                <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                    <Button variant="contained" color="success" onClick={() => setDialogOpen(true)}>
                        New
                    </Button>
                </FormControl>
            </Grid>

            {selectedProfile && profileSettings &&
                <Grid container>
                    <Grid item xs={12} sx={{ml: '50px', mt: '30px'}}>
                        <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                            <FormLabel id="demo-row-radio-buttons-group-label">Profile Type</FormLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Profile"
                                onChange={(e) => {
                                    setSelectedProfile({...selectedProfile, profile_type: e.target.value})
                                }}
                                value={selectedProfile.profile_type}
                            >

                                <MenuItem key={'preset'} value={'preset'}>
                                    Preset
                                </MenuItem>
                                <MenuItem key={'dynamic'} value={'dynamic'}>
                                    Dynamic
                                </MenuItem>

                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sx={{mt: '30px', ml: '40px'}}>
                        <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                            <FormLabel id="demo-row-radio-buttons-group-label">Editing Encoding</FormLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Profile"
                                onChange={(e) => {
                                    setSelectedProfile({...selectedProfile, editing_encoding: e.target.value})
                                }}
                                value={selectedProfile.editing_encoding}
                            >
                                {profileSettings.editing_encodings.map((encoding) => (
                                    <MenuItem key={encoding['name']} value={encoding['name']}>
                                        {encoding['name']}
                                    </MenuItem>)
                                )}
                            </Select>
                        </FormControl>
                        <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                            <FormLabel id="demo-row-radio-buttons-group-label">Result Encoding</FormLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Profile"
                                onChange={(e) => {
                                    setSelectedProfile({...selectedProfile, result_encoding: e.target.value})
                                }}
                                value={selectedProfile.result_encoding}
                            >
                                {profileSettings.result_encodings.map((encoding) => (
                                    <MenuItem key={encoding['name']} value={encoding['name']}>
                                        {encoding['name']}
                                    </MenuItem>)
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sx={{mt: '30px', ml: '40px'}}>

                        <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                            <FormLabel id="demo-row-radio-buttons-group-label">Audio</FormLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Profile"
                                onChange={(e) => {
                                    setSelectedProfile({...selectedProfile, sound_file: e.target.value})
                                }}
                                value={selectedProfile.sound_file}
                            >
                                {profileSettings.audios.map((audio) => (
                                    <MenuItem key={audio} value={audio}>
                                        {audio}
                                    </MenuItem>)
                                )}
                            </Select>
                        </FormControl>
                        <FormControl variant="standard" sx={{m: 1, minWidth: 360}}>
                            <FilePond
                                files={selectedFile2}
                                allowMultiple={false}
                                onupdatefiles={setSelectedFile2}
                                server={
                                    {
                                        process: (fieldName, file, metadata, load, error, progress, abort) => {
                                            const formData = new FormData();
                                            formData.append('file_type', 'audio')
                                            formData.append('file', file, file.name);

                                            const config = {
                                                onUploadProgress: (event) => {
                                                    // update the FilePond progress indicator
                                                    progress(event.lengthComputable, event.loaded, event.total);
                                                },
                                            };

                                            // use axios to post the formData to your server
                                            api.post('/api/v1/upload_static/', formData, config)
                                                .then(response => {
                                                    // pass the file id to the load method, so FilePond can update the file status
                                                    load(response.data.name);
                                                })
                                                .catch((thrown) => {
                                                    if (api.isCancel(thrown)) {
                                                        abort();
                                                    } else {
                                                        error('An error occurred');
                                                    }
                                                });
                                        }
                                    }
                                }
                                onprocessfile={(error, file) => {
                                    if (error) {
                                        console.error('Error while uploading file:', error);
                                        return;
                                    }
                                    setProfileSettings(prevSettings => ({
                                        ...prevSettings,
                                        audios: [...prevSettings.audios, file.serverId],
                                    }));
                                }}
                                credits={false}
                                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sx={{mt: '30px', ml: '40px'}}>
                        <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                            <FormLabel id="demo-row-radio-buttons-group-label">Endslate</FormLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Profile"
                                onChange={(e) => {
                                    setSelectedProfile({...selectedProfile, endslate: e.target.value})
                                }}
                                value={selectedProfile.endslate}
                            >
                                {profileSettings.endslates.map((endslate) => (
                                    <MenuItem key={endslate} value={endslate}>
                                        {endslate}
                                    </MenuItem>)
                                )}
                            </Select>
                        </FormControl>
                        <FormControl variant="standard" sx={{m: 1, minWidth: 360}}>
                            <FilePond
                                files={selectedFile}
                                allowMultiple={false}
                                onupdatefiles={setSelectedFile}
                                server={
                                    {
                                        process: (fieldName, file, metadata, load, error, progress, abort) => {
                                            const formData = new FormData();
                                            formData.append('file_type', 'endslate')
                                            formData.append('file', file, file.name);

                                            const config = {
                                                onUploadProgress: (event) => {
                                                    // update the FilePond progress indicator
                                                    progress(event.lengthComputable, event.loaded, event.total);
                                                },
                                            };

                                            // use axios to post the formData to your server
                                            api.post('/api/v1/upload_static/', formData, config)
                                                .then(response => {
                                                    // pass the file id to the load method, so FilePond can update the file status
                                                    load(response.data.name);
                                                })
                                                .catch((thrown) => {
                                                    if (api.isCancel(thrown)) {
                                                        abort();
                                                    } else {
                                                        error('An error occurred');
                                                    }
                                                });
                                        }
                                    }
                                }
                                onprocessfile={(error, file) => {
                                    if (error) {
                                        console.error('Error while uploading file:', error);
                                        return;
                                    }
                                    setProfileSettings(prevSettings => ({
                                        ...prevSettings,
                                        endslates: [...prevSettings.endslates, file.serverId],
                                    }));
                                }}
                                credits={false}
                                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                            />
                        </FormControl>
                    </Grid>

                    {selectedProfile.profile_type === 'preset' && presetProfileOptions()}

                    {selectedProfile.profile_type === 'dynamic' && dynamicProfileOptions()}

                </Grid>
            }

            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogContent>
                    <DialogContentText>
                        Profile Name
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Profile"
                        fullWidth
                        variant="standard"
                        onChange={e => {
                            setProfileName(e.target.value)
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => createProfile()}>Create</Button>
                </DialogActions>
            </Dialog>

        </Grid>

    );
};

export default Profile