import { Navigate, Outlet, Route } from "react-router-dom";
import { useEffect } from "react";
import { useNavigate } from "react-router";

// function ProtectedRoute({ isAuthenticated }) {
//   return (
//     <Route
//       element={
//         isAuthenticated ? (
//           <Outlet />
//         ) : (
//           <Navigate to="/login" replace />
//         )
//       }
//     />
//   );
// }

function ProtectedRoute({ isAuthenticated }) {
  const navigate = useNavigate();

  useEffect(() => {

    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);

  return isAuthenticated ? <Outlet /> : null;
}

export default ProtectedRoute;