import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {Routes, Route, BrowserRouter, Outlet} from "react-router-dom";
import Grid from "@mui/material/Grid";
import SidebarComponent from "./pages/sidebar";
import ProtectedRoute from "./components/protectedRoute"

import VideoCapture from "./pages/capture";
import VideoList from "./pages/video_list";

import LoginPage from "./pages/login";

import {ThemeProvider} from '@mui/material/styles';
import {GoogleOAuthProvider} from '@react-oauth/google';
import Theme from "./theme";
import Profile from "./pages/profile";

// import {setAuthInterceptor} from "./api";

const root = ReactDOM.createRoot(document.getElementById('root'));

const SidebarOutlet = () => {
    return (
        <Grid container>
            <Grid item>
                <SidebarComponent/>
            </Grid>
            <Grid item xs={9}>
                <Outlet/>
            </Grid>
        </Grid>
    );
}


const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(
        () => JSON.parse(localStorage.getItem("isAuthenticated")) || false
    );

    useEffect(() => {
        localStorage.setItem("isAuthenticated", JSON.stringify(isAuthenticated));
    }, [isAuthenticated]);


    return (
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={<LoginPage onLogin={setIsAuthenticated}/>}/>

                <Route
                    element={
                        <ProtectedRoute isAuthenticated={isAuthenticated}/>}>

                    <Route path="/" element={<SidebarOutlet/>}>
                        <Route path="/" element={<VideoCapture/>}/>
                        <Route path="/profile" element={<Profile/>}/>

                        <Route path="/video-list" element={<VideoList/>}/>

                        <Route path="/faq" element={<h1>FAQ</h1>}/>
                        <Route path="*" element={<h1>Not Found</h1>}/>
                    </Route>
                </Route>

            </Routes>
        </BrowserRouter>
    );
}


root.render(
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_SECRET}>
        <ThemeProvider theme={Theme}>
            <App/>
        </ThemeProvider>
    </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
