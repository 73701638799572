import { createTheme } from '@mui/material/styles';


const Theme = createTheme({
  spacing: 8,
  palette: {
    primary: {
      main: '#FFC145',
    },
    typography: {
      allVariants: {
        color: '#242423'
      },
    },
  },
});

export default Theme;