import React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import api from '../api';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Paper } from '@mui/material';
import { styled } from '@mui/system';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  maxWidth: 400,
  textAlign: 'center',
  margin: 'auto',
}));

const LoginPage = ({ onLogin }) => {
  const navigate = useNavigate();
  const onSuccess = (response) => {
    // Send the access token to the backend for further processing
    const idToken = response.credential;
    api
      .post('/api/auth/login/', { id_token: idToken })
      .then((response) => {
        onLogin(true);
        navigate('/');
      })
      .catch((error) => {
        console.log('Error saving user data:', error);
      });
  };

  const onFailure = (error) => {
    console.log('Error:', error);
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: (theme) => theme.palette.background.default,
      }}
    >
      <StyledPaper>
        <Typography variant="h3" component="h1" gutterBottom>
          Cinebooth
        </Typography>
        <Typography variant="h4" component="h2" gutterBottom>
          Login
        </Typography>
        <GoogleLogin
          clientId="YOUR_GOOGLE_CLIENT_ID"
          buttonText="Login with Google"
          onSuccess={onSuccess}
          onFailure={onFailure}
          cookiePolicy={'single_host_origin'}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        />
      </StyledPaper>
    </Box>
  );
};

export default LoginPage;