import React from 'react';
import {Link as RouterLink, useLocation} from 'react-router-dom';
import {Link} from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import {
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@mui/material';

import HomeIcon from '@mui/icons-material/Home';
import InboxIcon from '@mui/icons-material/Inbox';
import EditIcon from '@mui/icons-material/Edit';
import HelpIcon from '@mui/icons-material/Help';

const drawerWidth = 300;


function SidebarComponent() {
    const {pathname} = useLocation();

    const routes = {
        Capture: {route: "/", icon: <HomeIcon/>},
        Videos: {route: "/video-list", icon: <InboxIcon/>},
        Profile: {route: "/profile", icon: <EditIcon/>},
        FAQ: {route: "/faq", icon: <HelpIcon/>},
    };
    return (
        <Drawer
            variant="permanent"
            anchor="left"
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                },
            }}
            xs={4}
        >
            <Toolbar/>
            <Divider/>
            <List>
                {Object.keys(routes).map((routeName, index) => {
                    const route = routes[routeName];

                    return (
                        <Link component={RouterLink} key={route.route} to={route.route} sx={{
                            textDecoration: 'none',
                        }}>
                            <ListItem selected={route.route === pathname} button>
                                <ListItemIcon>
                                    {route.icon}
                                </ListItemIcon>
                                <ListItemText sx={{color: '#000000' }} primary={routeName}/>
                            </ListItem>
                        </Link>
                    );
                })}
            </List>
        </Drawer>
    )
}

export default SidebarComponent