import React, {useEffect, useRef, useState} from "react";
import {Alert, Button, Slider, Stack} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Webcam from "react-webcam";
import {Snackbar, Grid, Typography} from "@mui/material";
import Countdown from "../components/countdown";
import api from "../api";

const VideoCapture = () => {
    const [recording, setRecording] = useState(false);
    const webcamRef = useRef(null);
    const mediaRecorderRef = useRef(null);

    const [snackBarState, setSnackBarState] = React.useState({
        open: false,
        severity: 'info',
        message: 'SnackBar'
    });

    const [audioDevices, setAudioDevices] = useState([]);
    const [videoDevices, setVideoDevices] = useState([]);
    const [selectedAudioDevice, setSelectedAudioDevice] = useState('');
    const [selectedVideoDevice, setSelectedVideoDevice] = React.useState('');

    const [videoSettings, setVideoSettings] = useState({width: '', height: '', frameRate: ''})

    const [profiles, setProfiles] = useState([]);
    const [selectedProfile, setSelectedProfile] = useState(null);

    const [currentStream, setCurrentStream] = useState(null);


    const uploadData = (blob) => {
        const formData = new FormData();
        const data = blob.data
        if (data.size > 0) {
            formData.append('file', data, 'video.webm');
            formData.append('profile_id', selectedProfile.id);

            setSnackBarState({...snackBarState, open: true, message: 'Processing Video...', severity: 'info'});
            try {

                api.post('/api/v1/post_process/', formData, {}).then(response => {
                    setSnackBarState({
                        ...snackBarState, open: true, message: `Video Processed: ${response.data.code}`,
                        severity: 'success'
                    });
                }).catch(err => {
                    setSnackBarState({
                        ...snackBarState, open: true, message: 'Video Processing Error',
                        severity: 'error'
                    });
                })
            } catch (e) {
                console.log(e)
            }

        }

    }

    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackBarState({...snackBarState, open: false});
    }

    const handleStartCaptureClick = () => {
        setRecording(true);
        mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream);
        mediaRecorderRef.current.addEventListener("dataavailable", uploadData);
        mediaRecorderRef.current.start();
    };

    const handleStopCaptureClick = () => {
        setRecording(false);
        mediaRecorderRef.current.stop();
        mediaRecorderRef.current.removeEventListener("dataavailable", uploadData);
    };

    const getMediaDevices = async () => {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const audioInputDevices = devices.filter((device) => device.kind === "audioinput");
        const videoInputDevices = devices.filter((device) => device.kind === "videoinput");
        setAudioDevices(
            audioInputDevices.map((device) => ({
                deviceId: device.deviceId,
                label: device.label || `Microphone ${audioInputDevices.indexOf(device) + 1}`,
            }))
        );
        setVideoDevices(
            videoInputDevices.map((device) => ({
                deviceId: device.deviceId,
                label: device.label || `Camera ${videoInputDevices.indexOf(device) + 1}`,
            }))
        );

        setSelectedAudioDevice(
            audioInputDevices.length > 0
                ? audioInputDevices[0].deviceId
                : {}
        )
        setSelectedVideoDevice(
            videoInputDevices.length > 0
                ? videoInputDevices[0].deviceId
                : {}
        )
    }

    const getProfiles = () => {
        api.get('/api/v1/profile_list/')
            .then((response) => {
                setProfiles(response.data)
                setSelectedProfile(response.data[0])
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const handleGetUserMedia = (stream) => {
        const settings = stream.getVideoTracks()[0].getSettings();
        setVideoSettings({width: settings.width, height: settings.height, frameRate: settings.frameRate})
        // setCurrentStream(stream)
    }


    useEffect(() => {
        getProfiles();
        getMediaDevices();
    }, []);

    return (
        <Grid
            container
            alignItems="center"
            spacing={3}
            // style={{minHeight: '100vh'}}
        >
            <Grid item xs={12}>
                <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
                    {videoDevices && selectedVideoDevice &&
                        <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedVideoDevice}
                                label="Video Device"
                                disabled={recording}
                                onChange={(e) => {
                                    setSelectedVideoDevice(e.target.value)
                                }}
                            >
                                {videoDevices.map((device) => (
                                    <MenuItem key={device.deviceId} value={device.deviceId}>
                                        {device.label}
                                    </MenuItem>)
                                )}
                            </Select>
                        </FormControl>
                    }
                    {audioDevices && selectedAudioDevice &&
                        <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedAudioDevice}
                                label="Audio Device"
                                disabled={recording}
                                onChange={(e) => {
                                    setSelectedAudioDevice(e.target.value)
                                }}
                            >
                                {audioDevices.map((device) => (
                                    <MenuItem key={device.deviceId} value={device.deviceId}>
                                        {device.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    }
                    <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Profile"
                            disabled={recording}
                            value={selectedProfile ? selectedProfile.name : ''}
                            onChange={(e) => {
                                const profile = profiles.find((profile) => profile.name === e.target.value)
                                setSelectedProfile(profile)
                            }}
                        >
                            {profiles.map((profile) => (
                                <MenuItem key={profile.name} value={profile.name}>
                                    {profile.name}
                                </MenuItem>)
                            )}
                        </Select>
                    </FormControl>
                </Stack>
            </Grid>
            <Grid item xs={12} sx={{textAlign: "center"}}>

                <Webcam audio={true} videoConstraints={{
                    deviceId: selectedVideoDevice, width: {min: 640, ideal: 1920, max: 4096},
                    height: {min: 480, ideal: 1080, max: 2160}

                }}
                        style={{width: "70%"}}
                        onUserMedia={handleGetUserMedia}
                        audioConstraints={{deviceId: selectedAudioDevice}} ref={webcamRef}/>
            </Grid>
            <Grid item xs={2} sx={{textAlign: "center"}}>

            </Grid>

            <Grid item xs={2} sx={{textAlign: "center"}}>

                {selectedProfile && selectedProfile.profile_type == 'preset' && selectedProfile.preset !== 0 &&
                    <Countdown initialTime={selectedProfile.preset} trigger={recording}
                               onFinish={handleStopCaptureClick}></Countdown>
                }

            </Grid>
            <Grid item xs={4} sx={{textAlign: "center"}}>

                {(selectedProfile && selectedProfile.profile_type == 'preset' && selectedProfile.preset !== 0) ? (
                    <Button variant="contained" onClick={handleStartCaptureClick} disabled={recording}>
                        Start Recording
                    </Button>
                ) : (
                    <div>
                        {!recording ? (
                            <Button variant="contained" onClick={handleStartCaptureClick}>
                                Start Recording
                            </Button>
                        ) : (
                            <Button variant="contained" onClick={handleStopCaptureClick}>
                                Stop Recording
                            </Button>
                        )}
                    </div>

                )}

            </Grid>

            <Grid item xs={4}>
                <Typography gutterBottom>
                    Resolution
                </Typography>
                <Typography gutterBottom>
                    {videoSettings.width} x {videoSettings.height}
                </Typography>
                <Typography gutterBottom>
                    Frame Rate
                </Typography>
                <Typography>
                    {videoSettings.frameRate}
                </Typography>
            </Grid>

            <Snackbar
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                open={snackBarState.open}
                onClose={handleSnackBarClose}
            >
                <Alert onClose={handleSnackBarClose} severity={snackBarState.severity} sx={{width: '100%'}}>
                    {snackBarState.message}
                </Alert>
            </Snackbar>
        </Grid>
    );
};

export default VideoCapture