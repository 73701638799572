import React, {useEffect, useState} from "react";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import {ImageListItemBar, Pagination, Stack} from "@mui/material";
import usePagination from "../components/pagination";
import api from "../api";

const VideoList = () => {
    const [videos, setVideos] = useState([]);

    let [page, setPage] = useState(1);
    const PER_PAGE = 8;

    const count = Math.ceil(videos.length / PER_PAGE);
    const page_data = usePagination(videos, PER_PAGE);
    const getVideos = async () => {
        api.get('/api/v1/video_list/').then((response) => {
            setVideos(response.data.videos)
        }).catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {
        getVideos();
    }, []);

    const handleChange = (e, p) => {
        setPage(p);
        page_data.jump(p);
    };

    const openInNewTab = (url) => {
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
    }

    return (

        <Stack alignItems="center">
            <ImageList sx={{width: '100%', height: '100%', m: '30px'}} cols={4}>
                {page_data.currentData().map((item) => (
                    <ImageListItem key={item.id} onClick={() => openInNewTab(`${item.path}`)}>
                        <img
                            src={`${item.thumbnail}?w=164&h=164&fit=crop&auto=format`}
                            // srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                            alt={item.title}
                            loading="lazy"
                        />
                        <ImageListItemBar
                            title={item.code}
                            subtitle={<span>Date: {item.date}</span>}
                            position="below"

                        />
                    </ImageListItem>

                ))}
            </ImageList>
            <Pagination count={count}
                        size="large"
                        page={page}
                        onChange={handleChange}
                        color="primary"/>
        </Stack>


    );
};

export default VideoList