import React, {useState, useEffect} from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const Countdown = ({initialTime = 10, trigger, onFinish}) => {
    const [seconds, setSeconds] = useState(initialTime);
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        let interval = null;
        if (isActive && seconds > 0) {
            interval = setInterval(() => {
                setSeconds((seconds) => seconds - 1);
            }, 1000);
        } else if (isActive && seconds === 0) {
            if (onFinish) {
                onFinish();
            }
            setIsActive(false);
            setSeconds(initialTime);
        }
        return () => clearInterval(interval);
    }, [isActive, seconds, onFinish]);

    useEffect(() => {
        if (trigger) {
            setIsActive(true);
        }
    }, [trigger]);


    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            p: 2,
            backgroundColor: "#f5f5f5",
            borderRadius: 1,
            boxShadow: 1,
        }}>
            <Typography variant="h3" component="div">
                {seconds}s
            </Typography>

        </Box>
    );
};

export default Countdown;